.step-actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.step-content {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.step-hidden {
    display: none;
}

.modal-book-session {
    .modal-body {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }
}

.credit-status {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 0.7em
}