.dropdownToggle {
    margin: 0 !important;
}

a {
    .school-tag {
        text-decoration: underline;
        color: #0f6aa8 !important;
    }
}

.teacher-tag {
    text-decoration: underline;
    color: #0f6aa8 !important;
}

.wish-list {
    background-color: #ffcccb !important;
}

.session-card-container {
    display: flex;
    flex-direction: column;
    width: 100%
}

.img-pr {
    padding-right: 5px;
}

.token-blue {
    width: 28px;
    height: 28px;
    margin-right: 8px;
}

.btn-favourite {
    border-radius: 50% !important;
    text-align: center !important;
    background-color: white !important;
    box-shadow: none !important;
    border: none !important;
    margin-right: 0 !important;
    padding: 0 5px;
    height: 40px;
}

.session-actions {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .session-actions {
    flex-direction: column;
    
    .propose-alternates-btn {
      order: 3; // This will move it after both confirm buttons
    }
  }
}