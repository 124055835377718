.resource-order-item {
  display: grid;
  grid-template-columns: 1fr 1fr 20%;
  padding: 1.5rem 0;
  border-bottom: 1px solid #9de0f8;

  .order-date {
    padding-left: 1rem;
  }

  .resource-info {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .req-no {
      font-weight: bold;
    }
  }

  .resource-status {
    span {
      padding: 4px 12px;
      border-radius: 15px;
      font-size: 16px;
    }

    &.new-request span {
      background: #a5dff9;
    }

    &.received span {
      background: #dabbff;
    }

    &.denied span {
      background: #f7cecf;
    }

    &.ordered span {
      background: #a5f9c5;
    }
  }

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding: 1.5rem;

    .order-date {
      padding-left: 0;
    }
  }
}