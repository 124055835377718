@import "../../../../assets/scss/custom/variables.scss";

.resources-slider {
  .slider-control {
    position: absolute;
    top: 0;
    right: 12px;
    z-index: 11;

    display: flex;
    flex-direction: row;
    gap: 1px;
  }

  .slider-prev-arrow {
    cursor: pointer;
    min-width: 43px;
    min-height: 43px;
    background: #9de0f8;
    box-shadow: 10px 12px 24px rgba(206, 222, 238, 0.47);
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    outline: 0;
    color: blue;
    top: -2rem;
    left: unset;
    right: 56px;

    &:hover {
      background: rgba(0, 0, 0, 0.8);
    }

    &::before {
      font-size: 20px;
      color: #0e6aa7;
      content: "\e824";
      font-family: revicons;
      text-align: center;
    }
  }

  .slider-next-arrow {
    cursor: pointer;
    min-width: 43px;
    min-height: 43px;
    background: #9de0f8;
    box-shadow: 10px 12px 24px rgba(206, 222, 238, 0.47);
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    outline: 0;
    color: blue;
    top: -2rem;
    right: 12px;

    &:hover {
      background: rgba(0, 0, 0, 0.8);
    }

    &::before {
      font-size: 20px;
      color: #0e6aa7;
      content: "\e825";
      font-family: revicons;
      text-align: center;
    }
  }
}