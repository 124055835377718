.bp-container {
  display: flex;
  margin: 10px 0 20px 0;

  .bp-nav-bar {
    width: 310px;
    margin-right: 10px;

    .bp-nav-item {
      cursor: pointer;
      margin: 10px 0;
      font-size: 17px;
      display: flex;

      &.active {
        color: red;
      }

      .commitment-status {
        display: flex;
        width: 20px;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
      }

      .bp-nav-label {
        flex: 1;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .bp-content {
    margin-left: 10px;
    flex: 1;

    .embeded-conatiner {
      width: 100%;
      min-height: 300px;
      display: flex;
      margin-top: 30px;
      justify-content: center;
    }

    .main-content {
      color: #525f7f;
      font-size: 15px;
      font-weight: 300;

      .thumnail {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0;

        img {
          width: 100%;
          height: auto
        }
      }

      .fac-img {
        width: 100%;
        height: auto
      }
    }

    .ul1 {
      list-style: circle;
    }

    .ul2 {
      list-style: circle;
    }

    strong {
      font-weight: bold;
    }
  }
}

.confirm-reflection {
  padding-top: 20px;
  border-top-width: 2px;
  border-top-color: gray;
  border-top-style: dotted;
  margin-top: 20px;

  .reflection {
    margin-bottom: 1rem;
  }
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

ul.bp-menu {
  padding-left: 32px;
}

span.bp-link {
  text-decoration: underline;
  cursor: pointer;
}

p.no-margin {
  margin: 0
}

li p {
  margin: 0;
}

.survey-form {
  margin-top: 40px;
  padding: 0 20px;

  .error {
    color: red;
  }
}

.reflection-item-content {
  margin-bottom: 40px;

  h4 {
    font-weight: bold;
  }
}

.role-expectation {
  ul {
    padding-left: 1.75rem;
    margin-bottom: 1rem;

    &.mb0 {
      margin-bottom: 0;
    }
  }
}

.best-practices {
  a:hover {
    text-decoration: underline;
  }
}