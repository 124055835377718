@import "../../assets/scss/custom/variables.scss";

.bread ol {
    background-color: $light-blue;
    margin: 0;
}

.myCredit {
    padding: 30px;
    background-color: $secondary;
    border-radius: 15px;
    font-weight: 700;
    color: $dark-blue;
    box-shadow: 10px 12px 24px 0px rgba(206, 222, 238, 0.47); 
}

.h1 {
    font-weight: 700;
    color: $black;
    font-size: 30px;
    margin: 20px 0 40px;
}

.container {
    display: flex;
    gap: 30px;
    flex-direction: row;
    position: relative;
    
    .filter {
        position: sticky;
        position: -webkit-sticky;
        top: 40px;
        height: 300px;
        height: max-content;
        background-color: white;
        border-radius: 15px;
        border: 1px solid $dark-blue;
        flex-grow: 1;
        max-width: 300px; 
        h2 {
            color: $black;
            text-align: left;
            font-size: 18px;
            font-weight: 700;
            padding: 15px 0;
        }

        .filterInner {
            overflow: hidden;
            padding: 10px 0;
        }

        .item {
            border-bottom: 1px solid $secondary;
            box-shadow: none;
            padding: 0 10px;
            .header button {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 5px;
                color: $black;
                text-align: left;
                font-size: 18px;
                font-weight: 700;
                height: 15px;
            }
        }

    }

    .sessions {
        flex-grow: 1;
    }

    .slider {
        padding-bottom: 30px !important;
    }
    @media screen and (max-width: 768px){
        flex-wrap: wrap;
        .filter {
            max-width: 100%;
        }
    }
}
