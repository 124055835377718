p {
  font-weight: 400;
  line-height: 1.5em;
}

p.large {
  font-size: 1.125em;
}

.sig-number {
  font-size: 3.125em;
  font-weight: 700;
  color: $blue-secondary;
  display: flex;
  align-items: center;
  gap: 5px;
}

.sup-title {
  font-weight: 400;
  font-size: 1rem;
  color: $dark-blue;
}

// This is an overexcited bootstrap responsive thing that I think has been improved in Bootstrap 5 but here's a hotfix for this version

@include media-breakpoint-down(md) {
  .container {
    max-width: 100%;
  }
}

//Main Bootstrap Buttons, overriding some argon design system settings

.btn {
  border-radius: 1em;
  text-transform: none;
  font-size: 1em;
  border-width: 2px;
  font-weight: 400;
  padding-top: 0.4em;
  padding-bottom: 0.5em;

  &.btn-primary {
    background: $blue-secondary;
    border-color: $blue-secondary;

    &:hover {
      background: darken($blue-secondary, 10%);
    }
  }

  &.btn-outline-primary {
    color: $blue-secondary;
    border-color: $blue-secondary;

    &:hover {
      color: $white;
      background: $blue-secondary;
    }
  }

  &.btn-secondary {
    background: $secondary;
    border-color: $secondary;
  }

  &.btn-lightblue {
    @extend .btn-secondary;
  }

  &.btn-blue {
    @extend .btn-primary;
  }

  &.btn-link {
    font-size: 1em;
    color: $blue-secondary;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

// Custom Modal Styling for global modals, sizes are determined by props, spacing by variables //

.modal-dialog {
  &.modal-lg {
    .modal-content {
      border-radius: 20px;
      border: 1px solid rgba($secondary, 60%);
      box-shadow: 10px 12px 24px 0px $gray-600;

      .modal-header {
        padding-top: calc($modal-inner-padding/2 - 1em);
        padding-left: calc($modal-inner-padding/3);
        padding-right: calc($modal-inner-padding/3);
        border: 0;

        .modal-title {
          font-weight: 700;
          color: $black;
        }

        .close {
          position: absolute;
          top: 1em;
          right: 1em;
          opacity: 1;

          &>span:not(.sr-only) {
            font-size: 2em;
          }
        }

        @include media-breakpoint-up(lg) {
          padding-top: calc($modal-inner-padding - 2rem);
          padding-left: $modal-inner-padding;
          padding-right: $modal-inner-padding;
        }
      }

      .modal-body {
        padding-left: calc($modal-inner-padding/3);
        padding-right: calc($modal-inner-padding/3);

        @include media-breakpoint-up(lg) {
          padding-left: $modal-inner-padding;
          padding-right: $modal-inner-padding;
        }

        label {
          font-size: 1em;
          color: $black;
        }
      }

      .modal-footer {
        padding-bottom: calc($modal-inner-padding/2 - 1rem);
        padding-left: calc($modal-inner-padding/3);
        padding-right: calc($modal-inner-padding/3);
        border: 0;

        @include media-breakpoint-up(lg) {
          padding-bottom: calc($modal-inner-padding - 2rem);
          padding-left: $modal-inner-padding;
          padding-right: $modal-inner-padding;
        }
      }
    }
  }
}

// Form customizations //

.form-control {
  border-color: $secondary;
  border-radius: 0.625em;
  background: $light-blue;
  color: $black;
}


.input-group {
  background: $light-blue;
  display: flex;
  border-radius: 100px;
  flex-wrap: nowrap;
  box-shadow: none;

  .input-group-text {
    background: $light-blue;
  }

  .rdt {
    width: 95%;
  }
}

//MUI font fix 

.MuiPaper-root .MuiTypography-root {
  font-family: $font-family-base;
}

//Thumbnail but the whole thing, not cropped

.full-image-wrap {
  border-radius: 16px;
  width: 100%;
  overflow: hidden;

  img {
    max-width: 100%;
  }
}

// Responsive video fixes

.video-col {
  iframe {
    @extend .embed-responsive-item;
  }
}

//Breadcrumbs

.breadcrumb {
  background: transparent;
  color: $dark;

  .breadcrumb-item {
    a {
      color: $dark;
    }
  }
}

.credit-right-left-icon {
  width: 16px;
  height: 16px;
}

.platform-tour {
  @media (max-width: 479.8px) {
    display: none;
  }
}