@import "../../assets/scss/custom/variables.scss";

.card {
  background-color: white;
  border: 1px solid $secondary;
  border-radius: 16px;
  padding: 30px 40px 50px;
  box-shadow: 10px 12px 24px 0px rgba(206, 222, 238, 0.47);
  margin-bottom: 30px;

  img {
    margin: 10px 0 20px;
  }
}

.badge {
  background-color: white;
  position: relative;
  height: 100%;
  opacity: 0.5;
  width: 100%;
}

.badge[data-achieved="ok"] {
  opacity: 1;
}

.badgeHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    font-weight: bold;
    font-size: 1em;
    margin: 10px auto;
  }
}


.badgeProgress {
  position: absolute;
  top: 30%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  background-color: $secondary;
  color: $black;
  text-align: center;
  padding: 13px 0;
  font-weight: bold;
  opacity: 1 !important;
}

.EmptyModalGraph {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(#ffffff, 0.6);
  border-radius: 16px;

  a {
    text-decoration: underline;
    font-weight: bold;
    color: $black;
  }
}

.TextBox {
  background-color: $secondary;
  border-radius: 16px;

  text-align: center;
  padding: 20px 24px;
  max-width: 296px;
  color: $black;
}

.hideMobile {
  @media (max-width: 768px) {
    display: none;
  }
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}
