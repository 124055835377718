.t-content {
  background-color: white;
  border-bottom: 1px solid gray;
  padding: 15px;
  margin: 0 -20px 30px;
  position: sticky;
  top: 0;

  .i-list {
    i {
      color: #2c40d0;
      font-size: 16px;
    }

    color: black;
  }

  .t-content-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 12px;

    .policy {
      .i-policy {
        color: #ff8c6c;
        font-size: 20px;
      }

      span {
        color: #333;
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 540px) {
    display: none;
  }
}

.policy-item {
  &:not(:first-child) {
    margin-top: 20px;
  }
}

.contens-item {
  ul {
    padding-left: 1.75rem;
    margin-bottom: 1rem;

    &.mb0 {
      margin-bottom: 0;
    }
  }

  img {
    max-width: 100%;
    margin: 20px 0;
  }
}

.flate {
  margin-bottom: 0;
}

.scroll-to-top {
  cursor: pointer;
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  background-color: #0e6ba8;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #1375b7;
  }

  &:active {
    background-color: #075991;
  }
}

.fac-subtitle {
  text-decoration: underline;
}