html {
    scroll-behavior: smooth;
}
.studentImg {
    width: 50%;
    height: auto;
    margin-bottom: 20px;
}

.customizedContentImg {
    width: 40%;
    height: auto;
    margin-bottom: 20px;
    margin-right: 10px;
}

.traumaImg {
    width: 40%;
    height: auto;
    margin-bottom: 20px;
}

.trauma2Img {
    width: 70%;
    height: auto;
    margin-bottom: 20px;
}

.underline {
    text-decoration: underline;
}

.mt20 {
    margin-top: 20px;
}

span.ol {
    text-decoration: underline;
}



.resource-hub {
    section {
        padding-bottom: 60px;
        position: relative;
    }
    h2 {
        color: black;
        margin-bottom: 24px;
        max-width: 80%;
    }
 
}

.featured_latest {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    flex-wrap: wrap;
    & > div {
        display: flex;
        flex-direction: column;
        gap: 0px;
        max-width: 100%;
    }
    
}
.intro_text {
    max-width: 855px;
}

.resource_carousel {
    position: relative;
    .resource-title {
        margin-bottom: -100px;
        max-width: calc(100% - 100px);
    }
    .react-multi-carousel-list {
        margin-top: -20px;
        padding-top: 80px;
        
    }
}
.resource_carousel .carousel-wrap .carouselItem {
    padding: 0 30px;
}
.resource_carousel .carousel-wrap {
    margin: 0 -30px;
}
.resource_carousel .react-multiple-carousel__arrow--right {
    right: 30px;
}
.resource_carousel .react-multiple-carousel__arrow--left {
    left: calc(100% - 30px);
}
.flex-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    flex: 1;
}
.categories {
    display: flex;
    gap: 20px;
    
    .category {
        width: 160px;   
        max-width: 100%;   
        gap: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;
            
            color: black;
            font-size: 1.125rem;
            font-weight: 700;
            line-height: 1.125;
        }
    }
    .icon-wrap {
        border: 2px solid #9DE0F8;
        background: white;
        border-radius: 10px;
        width: 100%;
        height: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}
.resource_card {
    max-width: 100%;
    border: 1px solid #9DE0F8;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    h3 {
       font-size:1.125rem;
       font-weight: 700; 
       color: #0D1120;
       line-height: 1.125;
       margin-bottom: 0;
    }
    p{
        line-height: 1.1;
    }
    .date {
        display: block;
        font-size: 1rem;
    }
    .image-wrap {
        position: relative;
        overflow: hidden;
        img,iframe {
            position: absolute;
            object-fit: cover;
            min-width: 100%;
            min-height: 100%;
            width: 100%;
            height: 100%;
        }
    }
    .tag {
        position: absolute;
        bottom: 0;
        left: 0;
        color: black;
        font-size: 1rem;
        padding: 2px 6px;
        background: #9DE0F8;
        max-width: 100%;
        border-top-right-radius: 5px;
    }
    .card-text {
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    .btn-link {
        display: flex;
        text-decoration: underline;
        &.primary {
            color: #0E6BA8;
        }
        &.secondary {
            color: white;
        }
    }
    .readmore {
        margin-top: auto;
    }
    .readless {
        position: absolute;
        right: 5px;
        top: 5px;
        z-index: 5;
        font-weight: 700;
        color: black;
        background: white;
        font-size: 24px;
        text-align: center;
        height: 60px;
        width: 60px;
    }

}
.featured_card {
    max-width: 473px;
    background: #0E6BA8;
    color: white;
    flex: 1;
    display: flex;
    flex-direction: column;
    h3 {
        color: white;
    }
    .image-wrap {
        width: 100%;
        height: 229px;
    }
    .card-text {
        flex: 1;
    }
}
.latest_card {
    max-width: 473px;
    background: white;
    display: flex;
    .image-wrap {
        flex:1 0 205px;
        max-width: 205px;
        height: 100%;
        
    }
    .card-text {
        flex: 1;
    }
    .tag {
        width: 100%;
        text-align: center;
    }
}
.carousel_card {
    background: white;
    max-width: 473px;

    .image-wrap {
        width: 100%;
        height: 229px;
    }
}
.modal-card {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #000;
    padding: 16px;
    background: white;
    max-width: 860px;
    width: 90%;
    .image-wrap {
        width: 100%;
        height: 305px;
    }
}

.btn-download-resource {
    color: #0f6aa8;
    font-size: 16px;
    cursor: pointer;

    &:hover {
        color: #233dd2;
        text-decoration: none;
    }
}