.feedback-content {
  text-align: center;

  .form-group {
    text-align: left;
  }

  h3 {
    font-weight: bold;
    font-size: 24px;
  }

  .MuiRating-root {
    gap: 4px;
    svg {
      font-size: 28px;
    }
  }
}