@import "../../assets/scss/custom/variables.scss";

.school-resources {
  h2 {
    margin-bottom: 1.5rem;
  }
}

.no-result {
  padding: 1rem 0;
  text-align: center;

  img {
    margin-bottom: 1rem;
  }
}

.filtered-resources {
  display: grid;
  row-gap: 1rem;

  @media (min-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
}

.browser-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;

  .resource-filter {
    width: 280px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .resource-filter-result {
    flex: 1;
  }
}

.resource-search {
  display: flex;
  margin-bottom: 1.5rem;
  justify-content: center;
  gap: 1rem;

  .input-group {
    border: 1px solid $primary;
    overflow: hidden;
  }

  .btn-filter {
    display: none;

    @media (max-width: 768px) {
      display: block;
      font-size: 28px;
    }
  }
}

.resource-item-wrapper {
  padding: 0 12px;
  height: 100%;
}

.featured-resources {
  position: relative;
}

.slider-next-arrow {
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 0;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border-radius: 0;
  width: 10px !important;
  height: 43px !important;
  background: #9de0f8;
  box-shadow: 10px 12px 24px rgba(206, 222, 238, 0.47);
}

.detail-resource-page {
  .resource-detail-info {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media (min-width: 1024px) {
      flex-direction: row;
      gap: 2rem;
    }

    .resource-detail-beta-info {
      width: 100%;

      @media (min-width: 1024px) {
        width: 280px;
      }

      .beta-info-content {
        border-radius: 16px;
        border: 1px solid #0e6ba8;
        padding: 2.5rem 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;


        @media (min-width: 720px) {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 1rem;
        }

        @media (min-width: 1024px) {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }

        .beta-info-item {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          gap: 16px;

          &.info-isbn {
            grid-column: span 2;
          }

          img {
            width: 20px;
          }

          span {
            color: rgb(37, 37, 37);

            span {
              color: black;
              font-weight: 400;
            }
          }
        }
      }
    }

    .resource-detail-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .resource-title {
        margin-top: 1rem;
      }

      .resource-detail-image {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @media (min-width: 720px) {
          flex-direction: row;
        }

        .resource-image {
          flex: 2;
          border-radius: 16px;
          overflow: hidden;
          border: 1px solid #0e6ba8;

          img {
            width: 100%;
            height: 320px;
            object-fit: cover;
          }
        }

        .resource-actions {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          justify-content: space-between;

          button {
            width: 100%;
          }

          @media (min-width: 360px) {
            flex-direction: row;

            button {
              width: 50%;
            }
          }

          @media (min-width: 720px) {
            flex-direction: column;
            justify-content: flex-start;

            button {
              width: 240px;
            }
          }
        }
      }

      .resource-other-content {
        .resource-review {
          display: flex;
          flex-direction: row;
          gap: 1rem;
          align-items: center;
        }
      }
    }
  }

  .resource-review-list {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-top: 1rem;
  }

  .resource-author-content {
    border-radius: 16px;
    border: 1px solid #0e6ba8;
    padding: 1.5rem;
    display: flex;
    flex-direction: row;
    gap: 2rem;

    .resource-author-avatar {
      img {
        width: 240px;
        height: 240px;
      }
    }
  }
}

.resource-list {
  display: grid;
  grid-gap: 1rem;

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.resource-order-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.resource-request-list {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.related-resources {
  position: relative;
}

.related-sessions,
.provider-sessions {
  overflow: hidden;

  .carouselItem {
    padding-right: 0;
  }

  .carousel-container {
    margin-top: -45px;
  }
}

.admin-resource-order-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}