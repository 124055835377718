@media only screen and (min-height: 640px) {

  .top-gap,
  .bottom-gap {
    height: 100px;
  }
}

@media only screen and (max-height: 640px) {

  .top-gap,
  .bottom-gap {
    height: 64px;
  }
}

#rating-main-container::-webkit-scrollbar {
  display: none;
}

hr {
  margin: 0;
}

.developed-skills {
  margin: 1rem 0;

  .skills-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .skill {
      cursor: pointer;
      border-radius: 10px;
      background: #EAEAEB;
      color: #1A1824;
      font-size: 13.8px;
      line-height: 18px;
      padding: 6px 10px;

      &.active {
        background: #FF5D5D;
        color: white;
      }
    }
  }
}
