.warning {
  padding-bottom: 15px;

  span {
    color: red
  }


  span.edit-teacher-link {
    text-decoration: underline;
  }
}

.btn-edit-email {
  cursor: pointer;
  color: rgb(37, 37, 37);

  &:hover {
    color: rgb(64, 64, 64);
  }
}