.registering-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    text-align: center;

    .registering-item {
        width: 100%;
        text-align: left;
    }

    .registering-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 30px;
    }
}