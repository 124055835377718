.dynamic-collection-modal {
  .modal-header {
    padding-bottom: 0.5rem;
  }

  .modal-body {
    padding-top: 0.5rem;
  }

  form {
    margin-top: 0;
  }
}