.flex-wrap-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .multicheck-item {
        padding: 5px 10px;
        background: lightgray;
        border-radius: 5px;
        margin: 7px;
        cursor: pointer;
        font-size: 12px;
        color: black;

        &.item-checked {
            background: #ff5d5d !important;
            color: white !important;
        }
    }
}