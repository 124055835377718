.m-container {
  margin-top: 10px;
}

.question-form {
  margin-bottom: 30px;
  // margin: 0 20px;

  .actions {
    margin-top: 20px;
    text-align: center;
  }
}

.question-list {
  margin-top: 20px;

  .question-container {
    margin: 0 20px;
  }

  .question-item {
    padding: 0 12px;

    .q-content {
      p {
        margin-bottom: 5px;
      }
    }

    .q-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;

      .q-favourite {
        width: 50px;
        cursor: pointer;
        padding: 0 5px;

        i {
          color: red;
        }
      }

      .q-date,
      .q-poster {
        color: gray;
      }
    }
  }

  hr {
    margin-top: 0;
  }
}

.btn-social {
  padding: 0.0rem 0.4rem;
  border-width: 1px;
  border-radius: 8px !important;
  border-color: lightgray !important;
  font-size: 12px !important;

  i {
    color: red;
  }

  span {
    font-size: 14px;
  }

  color: gray !important;
}

.q-favor {
  font-size: 12px;

  span {
    font-size: 16px;
  }
}