.tagline {
    margin-bottom: 1rem;
    display: block;
    font-weight: 700;
}

.about-provider {
    align-items: center;
}

.session-detail-button {
    width: 100%
}