.required {
    color: red;
}

.optional-textarea {
    height: 70px !important;
}

.form-action-container {
    text-align: center;
}

.result {
    padding-top: 20px;

    p {
        white-space: pre-line;
    }
}

form {
    margin-top: 20px;
}

.prompt-cursor {
    font-size: 24px;
    animation: blink 1s infinite;
}

@keyframes blink {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

.openai-result-card {
    margin-bottom: 20px;
    position: relative;

    .copy-clipboard {
        position: absolute;
        top: 0;
        right: 0;

        button {
            border-radius: 5px;
            border-width: 1px;
            border-color: lightgray;
            padding-top: 0.2rem;
            padding-bottom: 0.2rem;
        }
    }
}