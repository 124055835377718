.datepicker-container {
    position: relative;

    .suffix {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 20px;

        display: flex;
        align-items: center;

        i {
            cursor: pointer;
        }
    }
}