@import "../../../assets/scss/custom/variables.scss";

.ContainerGraph {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 10;

	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(#ffffff, 0.6);
	border-radius: 16px;

	a {
		text-decoration: underline;
		font-weight: bold;
		color: $black;
	}
}

.TextBox {
	background-color: $secondary;
	border-radius: 16px;

	text-align: center;
	padding: 20px 24px;
	max-width: 296px;
	color: $black;

	p {
		margin-bottom: 0;
	}
}
