.selectedFunderInfo {
  margin-top: 5px;

  span {
    color: #000;
    font-size: 15px;
  }

  div {
    margin-top: 10px;
  }
}

.errors {
  margin-top: 10px;
  span {
    color: #ca8b26;
    font-size: 15px;
  }
}