.btn-send-msg {
  margin-bottom: 16px;

  span {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}