:root {
    --parameter-line-clamp: 0;
}

.multiline-ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: pre-wrap;
}

.multiline-ellipsis {
    &.overflow {
        -webkit-line-clamp: var(--parameter-line-clamp);
    }
}

button {
    background: none;
    border: none;
    color: blue;
    cursor: pointer;
}