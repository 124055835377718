.admin-resource-order-item {
  border-bottom: 1px solid #9de0f8;
  min-height: 80px;
  padding: 12px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
  row-gap: 8px;

  @media (min-width: 600px) {
    flex-direction: row;
  }

  @media (min-width:  880px) {
    display: grid;
    grid-template-columns: 1.2fr 180px 1fr 160px 160px;
  }

  .order-item {
    display: flex;
    flex-direction: column;

    @media (min-width: 880px) {
      justify-content: center;
    }
  }

  .order-teacher {
    font-weight: 600;

    .name {
      font-size: 16px;
      font-weight: 600;
    }

    .school {
      font-weight: normal;
      font-size: 14px;
      color: #666;
    }

    @media (min-width: 600px) {
      width: 40%;
    }

    @media (min-width: 880px) {
      width: 100%;
    }
  }

  .order-info {
    @media (min-width: 600px) {
      width: 32%;
    }

    @media (min-width: 880px) {
      width: 100%;
    }

    .order-no {
      font-size: 17px;
      font-weight: 600;
    }

    .order-count {
      font-size: 14px;
    }
  }

  .order-date {
    font-weight: 600;

    @media (min-width: 600px) {
      width: 28%;
    }

    @media (min-width: 880px) {
      width: 100%;
    }
  }

  .order-status {
    align-items: flex-start;
    position: absolute;
    top: 14px;
    right: 0px;

    @media (min-width: 600px) {
      position: unset;
      width: 30%;
      justify-content: center;
    }

    @media (min-width: 880px) {
      width: 100%;
      align-items: center;
    }

    span {
      padding: 4px 12px;
      border-radius: 15px;
      font-size: 14px;

      &.new-request {
        background: #a5dff9;
      }

      &.received {
        background: #dabbff;
      }

      &.denied {
        background: #f7cecf;
      }

      &.ordered {
        background: #a5f9c5;
      }

      &.awaiting {
        background: #9df8c0;
      }
    }
  }

  .order-actions {
    padding: 8px 0;
    gap: 8px;
    flex-direction: row;
    padding-top: 1rem;

    @media (min-width: 600px) {
      flex-direction: row-reverse;
      justify-content: end;
      width: 70%;
    }

    @media (min-width: 880px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

      button {
        width: 120px;
      }
    }

    button {
      margin-right: 0;
      font-size: 14px;
      border-width: 1px;
      font-weight: 400;
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }
}