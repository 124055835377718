.curriculum-list-container {
  margin: 20px 0;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  @media screen and (min-width: 800px) {
    gap: 20px 50px;
  }
}

.curriculum-list-layout {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;

  @media screen and (min-width: 800px) {
    flex-direction: row;
    column-gap: 20px;
  }
}

.curriculum-list-filter {
  flex: 1;
  padding-right: 0;
  padding-top: 2em;
}

.curriculum-list-teacher-focus {
  flex: 1;
}

.curriculum-list-header {
  font-weight: 700;
}

.curriculum-list-ul {
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }

  justify-items: stretch;
  gap: 20px;
}

.filter-dropdowns-containers {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-wrap: wrap;

  &>div {
    width: 100%;
  }
}

.cfilter-actions {
  margin-top: 1.5rem;
}

section.teacher-focus {
  padding: 1.5rem;

  @media (min-width: 1280px) {
    padding: 3rem;
  }

  @media (min-width: 768px) {
    padding: 2rem;
  }
}