.transferRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  padding-top: 1rem;
  align-items: center;

  label {
    margin-right: 40px;
  }

  .full {
    flex: 1;
  }
}

.errors {
  height: 20px;
  span {
    font-size: 14px;
    color: red;
  }
}