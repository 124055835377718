.session-warning {
  margin: 20px 0;
  padding: 10px;
  border-radius: 4px;

  &.warn-msg-red {
    background-color: #ffe6e6;
    border: 1px solid #ffcccc;
  }

  span {
    color: rgb(94, 36, 36);
    font-size: 1rem;
  }
}
