.curriculum-suggest {
    max-width: 640px !important;

    .links {
        margin-top: 20px;
        max-height: 528px;
        overflow-y: scroll;
    }
}

.suggest-link {
    padding: 10px 20px;
    background-color: lightgray;
    border-radius: 3px; 
    margin-bottom: 5px;
    cursor: pointer;

    &:hover {
        background-color: #E0E0E0;
    }

    &:active {
        background-color: #b5b5b5;
    }
}

.link-title {
    color: black;
    font-size: 16px;
    margin-bottom: 5px;
}

.full {
    flex: 1
}

.selector {
    padding: 0 7px;
}