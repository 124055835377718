.cn-datetime-picker {
  .MuiOutlinedInput-root {
    fieldset {
      border: 1px solid $secondary !important;
      border-radius: 0.625em;
    }
  }

  &:hover {
    .MuiOutlinedInput-root {
      fieldset {
        border-color: $secondary !important;
      }
    }
  }
}