.min-body {
    min-height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.select-teacher, .st-customize-msg {
    margin-top: 20px;
}

.no-margin {
    margin: 0 !important;
}

.st-customize-msg {
    .message {
        height: 100px;
    }
}

.matched-teachers-selector {
    margin-bottom: 30px;
}

.filter-options {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;

    .filter-by-region,
    .filter-by-grade {
        flex: 1;
    }
}
