.resource-order-split-item {
  background: white;
  border-radius: 1rem;
  box-shadow: 10px 12px 24px rgba(206, 222, 238, 0.47);
  position: relative;
  padding-left: 2rem;

  .request-select {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .request-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .title {
      font-size: 20px;
      font-weight: bold;
    }

    .request-info-content {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .beta-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      .request-quantity {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        .form-control {
          width: 120px;
          font-size: 20px;
        }
      }
    }
  }

  @media (min-width: 560px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-right: 1rem;
    gap: 8px;
  }

  @media (min-width: 992px) {
    .request-info {
      .request-info-content {
        flex-direction: row;
      }
    }
  }
}