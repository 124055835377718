// .search-container {
//     padding-top: 150px;
// }

.search-suffix {
	display: flex;
	flex-direction: row;
	padding: 0.25rem;
}

.search-actions {
	// position: fixed;
	z-index: 5;
	width: 100%;
	max-width: map-get($container-max-widths, "xl");
	background-color: #f3f9ff;
	top: 0;
	padding-bottom: 1rem;

	@media (min-width: 768px) {
		margin-left: 1rem;
	}
}

.search-actions h1 {
	@media (max-width: 768px) {
		font-size: 1.5em;
	}
}

.search-actions-col1 {
	display: flex;
	justify-content: flex-start;
	padding-bottom: 0;
	width: 100%;

	h1 {
		font-size: 2.5rem;
	}
}

.search-input-org {
	justify-content: center;
	padding-top: 4px;

	.input-group {
		border: 1px solid $primary;
		overflow: hidden;
	}

	.dropdown-toggle {
		box-shadow: none;
		border: 1px solid $primary !important;
		border-bottom: 1px solid $primary !important;
		transform: translate(0);
	}
}

.search-actions-credits {
	font-weight: bold;
	color: #2b4f76;
	font-size: 1.125em;
}

.search-bar {
	position: relative;

	.btn-primary {
		&.not-activated {
			display: none;
		}

		@media (min-width: 768px) {
			position: fixed;
			bottom: 20px;
			margin-left: 10px;
		}
	}
}

.search-accordian-group {
	background: #ffffff;
	border: 1px solid $primary;
	box-shadow: 10px 12px 24px $theme-shadow;
	border-radius: 16px !important;
	overflow: hidden;
	margin-bottom: 1em;

	.MuiAccordionSummary-content {
		font-weight: bold;
	}

	.MuiPaper-root {
		background: transparent;
		border: 0;
		box-shadow: none;

		&:before {
			background-color: rgba($teal, 0.49);
		}
	}
}

.desktop-filter-apply-btn {
	bottom: 20px;
	margin-left: 10px;
	position: fixed;

	&.not-activated {
		display: none;
	}
}

.modal-filter-body {
	max-height: 75vh;
	overflow: auto;
}