.navbar .collapsing .navbar-toggler{
  pointer-events: none;
}

.navbar-teacher {
  background: #FFFFFF;
  box-shadow: 10px 12px 24px rgba(206, 222, 238, 0.47);
  i {
    font-size: 27px
  }
  
  .nav-items {
    list-style-type: none;
    display: flex;
    gap: 10px;
    width: 100%;
    .fa {
      color: #1094CE!important;
    }
    svg.nav-icon {
      filter:  invert(44%) sepia(61%) saturate(1020%) hue-rotate(161deg) brightness(94%) contrast(91%);
      font-size: 27px;
      width: 27px;
      height: 27px;
    }
  }
  
  .open-state .nav-link-icon {
    img {
      margin-right: 1.5em;
    }
    svg, i {
      margin-right: 15px;
      vertical-align: middle;
    }
  }

  .sub-closed .nav-panel {
    display: none;
  }

  .nav-toggler {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: auto;
   
  }
}
@media screen and (min-width: 768px) {
  
  header {
    float: left;
    height: 100vh;
    top: 0;
    position: sticky;
    z-index: 1001;
    margin-bottom: -70px;
  }
  .navbar-teacher {
    height: 100%;
  
  }
  .navbar-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    height: 50px;
    gap: 30px;
    padding: 0 20px;
    
  }
  .nav-toggler {
    justify-content: center;
    .nav-link-inner--text {
      display: none;
    }
    
  }
  .navbar-image-wrap {
    height: 100%;
    max-width: 165px;
    position: relative;
    img {
      height: 100%;
      max-width: 100%;
      object-fit: contain;
    }
  }
  .nav-items {
    flex-direction: column;
    margin-bottom: 40px;
  }
  
  .open-state {
    display: block;
    padding: 20px 15px 30px;
    .navbar-top{
      min-width: 348px;
    }
    .nav-items {
      & > li {
        padding: 10px 20px;
      }
    }
    .subnav-toggler {
      padding: 14px 30px;
      position: relative;
      z-index: 2;
    }
  }

  .sub-open > .nav-panel {
    display: flex;
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: max-content;

    flex-direction: column;
    align-items: center;

    .nav-items {
      width: 100%;
      position: relative;
      background: white;
      box-shadow: 10px -4px 24px rgba(206, 222, 238, 0.47);
      padding: 20px 10px 30px 10px;
      align-items: flex-start;
      margin-bottom: -12px;
    }
  }

  .collapsed-state {
    padding: 30px 10px;
    .navbar-top{
      
      .nav-toggler {
        margin: 0 auto;
      }
    }
    
    .navbar-image-wrap {
      display: none;
    }
    .nav-link-inner--text {
      display: none;
    }
    .nav-items {
      align-items: center;
    }
    .subnav-toggler {
      display: flex;
      justify-content: center;
    }
    .sub-open .nav-panel{
      display: flex;
      position: absolute;
      left: calc(100% + 10px);
      bottom: -80px;
      width: max-content;
      height: max-content;
  
      flex-direction: column;
      align-items: center;
  
      .nav-items {
        width: 100%;
        position: relative;
        background: white;
        padding: 20px 5px;
        box-shadow: 10px -4px 24px rgba(206, 222, 238, 0.47);
        text-align: left;
        align-items: flex-start;
      }
    }
  }

  .subnav-toggler {
    background: #F3F9FF;
    border-radius: 100px;
    cursor: pointer;
  }
  .sub-closed,.sub-open {
    position: relative;
    width: 100%;
    
  } 
}

@media screen and (max-width: 768px) {
  header {
    position: fixed;
    bottom: 0;
    z-index: 1001;
    width: 100%;
    
  }
  .navbar-teacher {
    border-radius: 16px 16px 0px 0px;
    box-shadow: 0px 5px 35px rgba(43, 79, 112, 0.2);
  }
  .navbar-top{
    width: max-content;
    padding-right: 32px;
    display:flex;
  }
  .navbar-image-wrap {
    display: none;
  }
  .nav-items {
    flex-direction: row;
    justify-content: space-evenly;
    flex: 1;
    align-items: flex-end;
    gap: 8px;
  }
  .nav-items li {
    display: flex; 
  }
  .nav-link {
    padding: 0;
    font-size: 15px;
  }
  .nav-item .nav-link-icon{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .open-state,.collapsed-state {
    width: 100%;
    padding: 20px 20px 15px 15px;
    display: flex;
  }

  .nav-toggler {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;

    .nav-link-inner--text{
      display: block;
      font-size: 15px;
      margin-top: 4px;
      
    }
  }
 
  .nav-link-inner {
    line-height: 1;
  }

  .nav-item .nav-link-icon{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
  }
  
  .open-state,.collapsed-state{
    .nav-items{
      align-items: flex-end;
    }
  }

  .navbar-teacher .open-state .nav-link-icon {
    img {
      margin-right: 0;
      margin-bottom: 2px;
    }
    svg, i {
      margin-right: 0;
      vertical-align: middle;
    }
  }

  .sub-open {
    .nav-link-icon .nav-link-inner--text{
      margin-right: 1.5rem;
    }
  
    .nav-panel {
      position: absolute;
      bottom:100%;
      left: 0;
      background: white;
      display: flex !important;      
      box-shadow: 10px -4px 24px rgba(206, 222, 238, 0.47);
      padding: 20px 10px;
      .nav-items {
        position: relative;
        flex-direction: column;
        padding: 0 20px 0 20px;
        margin-bottom: 0;
        align-items: flex-start;

        // Hiding the icons in the more menu
        img, i, svg {
          display: none;
        }
      }
        
    }
  }
  .subnav-toggler {
    display: none;
  }
}

@media screen and (min-height: 790px) {
  .navbar-teacher .nav-items {
    list-style-type: none;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    width: 100%;
    justify-content: space-evenly;
  }
}

@media screen and (min-height: 710px) and (max-height: 790px) {
  .navbar-teacher .nav-items {
    list-style-type: none;
    display: flex;
    grid-gap: 0px;
    gap: 10px;
    width: 100%;

  }
}

@media screen and (max-height: 710px) {
  .navbar-teacher .nav-items {
    list-style-type: none;
    display: flex;
    grid-gap: 0px;
    width: 100%;
    gap: 0px;
  }

  .sub-open .nav-items .nav-item {
    padding: 0;
  }
}
