.school-resource-order-item {
  display: grid;
  grid-template-columns: 1fr 180px 1.2fr 160px 160px;
  padding: 2rem 0;
  border-bottom: 1px solid #9de0f8;

  .order-teacher {
    font-weight: 600;
  }

  .order-info {
    display: flex;
    flex-direction: column;

    .order-no {
      font-size: 17px;
      font-weight: 600;
    }
  }

  .order-status {
    display: flex;
    align-items: center;

    span {
      padding: 4px 12px;
      border-radius: 15px;
      font-size: 14px;

      &.new-request {
        background: #a5dff9;
      }

      &.received {
        background: #dabbff;
      }

      &.denied {
        background: #f7cecf;
      }

      &.ordered {
        background: #a5f9c5;
      }
    }
  }

  .order-actions {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      font-size: 14px;
      border-width: 1px;
      font-weight: 400;
    }
  }
}