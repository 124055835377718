.review-resource-request-modal {
  .modal-header {
    padding-top: 2rem !important;
    padding-bottom: 20px !important;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .modal-body {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .modal-footer {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    justify-content: space-between;
  }
}