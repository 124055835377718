.copy-to-clipboard-container {
  position: relative;

  .copy-to-clipboard-text {
    padding-left: 14px;
    padding-right: 60px;
    border-color: #9de0f8;
    border-radius: 0.625em;
    background: #f3f9ff;
    border-width: 1px;
    border-style: solid;

    span {
      font-size: 32px;
    }
  }

  .addon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    span {
      cursor: pointer;
      color: #609eb4;
      font-size: 30px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}