.upcoming-tabs {
  margin-bottom: 0px;
}

.filter-conditions {
  background-color: white;
  border-radius: 5px;
  padding: 10px 20px 12px 20px;
  margin-bottom: 20px;
}

.filter-col {
  margin-top: 10px;
  text-align: center;
}

.filter-col-selector {
  margin-top: 10px;
}

.filter-col-in-modal {
  margin-top: 10px;
}

.right-col {
  padding: 0;
  text-align: right
}

.upcoming {
  .tab-content {
    margin-top: 20px;
  }
}

.upcoming-loader {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upcoming-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .filter {
    cursor: pointer;
    font-size: 20px;
    min-width: 48px;

    span {
      margin-left: 4px;
      font-size: 22px;
    }

    .lbl-filter {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
}