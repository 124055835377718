._2fa-configuration .modal-body {
  padding: 1.5rem !important;

  .close>span:not(.sr-only) {
    font-size: 3rem;
    width: unset;
    height: unset;
  }
}

._2fa-head {
  margin-bottom: 1rem;

  h3 {
    text-align: center;
  }
}

._2fa-content {
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    text-align: center;
  }
}

._2fa-code-container {
  position: relative;
  width: 100%;
  max-width: 480px;
  padding: 20px 10px;
  margin: 0 auto 20px auto;
  border: 4px solid transparent;

  ._2fa-container {
    width: 100%;
    height: 54px;
  }

  ._2fa-char {
    border: 1px solid #9de0f8;
    border-radius: 8px;
    color: #32325d;
  }

  ._2fa-char-selected {
    outline: 2px solid #7ad8fa;
  }

  ._2fa-char-inactive {
    background-color: #e3e3e3;
  }

  ._2fa-error {
    color: red;
    font-size: 12px;
  }
}

._2fa-alert {
  font-size: 14px;
  color: blue;
  margin-top: -10px;
  span {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

._2fa-alert1 {
  font-size: 14px;
  color: blue;
  margin-top: 4px;
  span {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

._2fa-code-verifying {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  opacity: 0.4;
  z-index: 99;
  overflow: hidden;
}

._2fa-actions {
  margin-top: 32px;
  text-align: center;
}

._2fa-app-guide-step {
  margin: 12px 0;
  display: flex;
  flex-direction: row;
  gap: 32px;

  ._2fa-step-logo {
    width: 200px;

    img {
      width: 100%;
      height: auto;
    }
  }

  ._2fa-step-content {
    flex: 1;

    span {
      color: lightgray;
    }

    p,
    li {
      color: black;
    }

    ul {
      margin-bottom: 1rem;
      padding-left: 40px;
    }

    .nomargin {
      margin: 0;
    }
  }
}