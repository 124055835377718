.footer {
  background: white;
  padding: 1.5rem;
  margin-top: 1.5rem;
  text-align: center;

  .col-footer {
    .heading {
      color: $footer-heading-color;
      letter-spacing: 0;
      font-size: $footer-heading-font-size;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      margin-bottom: 1rem;
    }
  }

  .nav .nav-item .nav-link,
  .footer-link {
    color: $footer-link-color !important;

    &:hover {
      color: $footer-link-hover-color !important;
    }
  }

  .list-unstyled li a {
    display: inline-block;
    padding: 0.125rem 0;
    color: $footer-link-color;
    font-size: $footer-link-font-size;

    &:hover {
      color: $footer-link-hover-color;
    }
  }

  .copyright {
    font-size: $font-size-sm;
  }

  .dropdown {
    .btn:not(:disabled):not(.disabled):active:focus,
    .btn:not(:disabled):not(.disabled).active:focus {
      box-shadow: none;
    }
  }
}

.footer-dark {
  .col-footer .heading {
    color: $white;
  }
}

.footer.has-cards {
  overflow: hidden;
  padding-top: 500px;
  margin-top: -420px;
  position: relative;
  background: transparent;
  pointer-events: none;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 600px;
    height: 2000px;
    background: theme-color('secondary');
    transform: skew(0, -8deg);
  }

  .container {
    pointer-events: auto;
    position: relative;
  }
}

.nav-footer {
  .nav-link {
    font-size: $font-size-sm;
  }

  .nav-item:last-child {
    .nav-link {
      padding-right: 0;
    }
  }
}

.horizontal-scrollview {
  width: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: row;
  padding: 2px 0;

  /* width */
  &::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
