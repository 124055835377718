.resource-request-modal {
  position: relative;

  .modal-body {
    margin: auto;
    padding: 1rem;

    .resource-request-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 320px;
      color: black;
      gap: 12px;

      span.resource-author {
        font-weight: bold;
        font-size: 22px;
      }

      .resource-info {
        :not(:last-child) {
          border-right: 1px solid #525f7f;
        }

        span {
          padding: 0 1rem;
          font-size: 16px;
        }
      }

      .resource-request-amount {
        .input-group-text {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          font-size: 16px;
        }

        input {
          font-size: 18px;
          padding-left: 12px;
          border-color: #cad1d7;
        }
      }
    }
  }

  .modal-loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(black, 0.5);
  }
}
