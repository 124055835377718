.bp-container {
  display: flex;
  margin: 10px 0 20px 0;

  .bp-nav-bar {
    position: sticky;
    top: 0px;
    width: 310px;
    margin-right: 10px;

    .bp-nav-item {
      cursor: pointer;
      margin: 10px 0;
      font-size: 17px;
      display: flex;

      &.active {
        color: red;
      }

      .commitment-status {
        display: flex;
        width: 20px;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
      }

      .bp-nav-label {
        flex: 1;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .bp-content {
    margin-left: 10px;
    flex: 1;

    .embeded-conatiner {
      width: 100%;
      min-height: 300px;
      display: flex;
      margin-top: 30px;
      justify-content: center;
    }

    .main-content {
      color: #525f7f;
      font-size: 15px;
      font-weight: 300;

      .thumnail {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0;

        img {
          width: 100%;
          height: auto
        }
      }

      .fac-img {
        width: 100%;
        height: auto
      }
    }

    .ul1 {
      list-style: circle;
    }

    .ul2 {
      list-style: circle;
    }

    strong {
      font-weight: bold;
    }

    img.sh-img {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

.confirm-reflection {
  padding-top: 20px;
  border-top-width: 2px;
  border-top-color: gray;
  border-top-style: dotted;
  margin-top: 20px;

  .reflection {
    margin-bottom: 1rem;

    .form-control {
      margin-top: 0.5rem;
    }
  }
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

ul.bp-menu {
  padding-left: 32px;
}

span.bp-link {
  text-decoration: underline;
  cursor: pointer;
}

p.no-margin {
  margin: 0
}

li p {
  margin: 0;
}

img.introduction {
  width: 100%;
  height: auto;
}

img.great {
  width: 100%;

  @media (min-width: 1024px) {
    width: 80%;
  }
}

span.nav-action {
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
}

.nav-content-wrap {
  margin-top: 10px;
  width: 100%;
}

.main-content {
  display: flex;
  flex-direction: column;
}

img.great-logo {
  width: 100%;
  height: auto;
  margin-bottom: 20px;

  @media (min-width: 1024px) {
    max-width: 300px;
  }
}

.image-text-container {
  display: flex;
  align-items: flex-start; /* Align items to the top */
}

.host-menu {
  width: 150px;
  margin-bottom: 20px;
  margin-right: 20px;
  flex-shrink: 0; /* Prevent the image from shrinking */
}

.text-content {
  flex: 1; /* Allow text to take up remaining space */
}

ul.session-host {
  margin-bottom: 1rem;
  padding-left: 2rem;

  li {
    font-size: 1rem;
    font-weight: 400;
  }

  &.mb0 {
    margin-bottom: 0;
  }
}

ol li {
  font-size: 1rem;
  font-weight: 400;
}

span {
  font-size: 1rem;
  font-weight: 400;
}

.ul-minus {
  list-style-type: "- ";
}

.host-training {
  .main-content p a {
    text-decoration: underline;
  }
  .example-session {
    margin: 20px 0;
  }
}