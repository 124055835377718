.dropzone-container {
    padding: 15px;
    border: 1px solid #9de0f8;
    border-radius: 8px;
    margin-bottom: 10px;
}

.horizontal-wrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px 0;
}

.full-drag {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .btn {
        margin-top: 10px;
    }
}

.small-drag {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}