.school-resource-item {
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #0e6ba8;
  height: 100%;

  .resource-image {
    position: relative;

    img {
      width: 100%;
      height: 240px;
      background: white;
    }

    .resource-type {
      position: absolute;
      left: 0;
      bottom: 0;
      background: #9de0f8;
      padding: 6px 12px;

      span {
        font-size: 14px;
        color: black;
      }
    }
  }

  .resource-content {
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    .resource-title {
      height: 60px;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      box-orient: vertical;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bold;
      font-size: 20px;
    }

    .resource-author {
      display: -webkit-box;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      box-orient: vertical;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .resource-other-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .resource-reviews {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        label {
          margin-bottom: 0;
        }

        .MuiRating-root {
          span {
            color: #0a4d79;
            font-size: 20px;
          }
        }
      }
    }

    .resource-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .resource-favorite {
        width: 32px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        i {
          font-size: 22px;
        }
      }
    }
  }
}