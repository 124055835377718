$red:            #ff5d5d;
$blue-secondary: #0e6ba8;
$dark-blue:      #2b4F70;
$light-blue:     #f3f9ff;
$black:          #0D1120;

$primary:       $blue-secondary;
$secondary:     #9de0f8;

$theme-shadow: rgba(206, 222, 238, 0.47);

$modal-inner-padding: 6.5rem;