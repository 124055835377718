@import "../../assets/scss/custom/variables.scss";

.validations {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.validationItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 2px 0;
}

.check {
  width: 18px;
  height: 18px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: green;
  margin-right: 8px;
  margin-top: 2px;
  i {
    color: white;
    font-size: 12px;
  }
}

.close {
  width: 18px;
  height: 18px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  margin-top: 2px;
  background-color: red;
  i {
    color: white;
    font-size: 12px;
  }
}

.valid {
  color: green;
  font-size: 14px;
}

.invalid {
  color: red;
  font-size: 14px;
  flex: 1;
}

span.lbl {
  font-size: 12px;
}