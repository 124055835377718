/* 
* SORRY KAT :(
* this needs to be overriden to matched figma, I know you're trying to clean 
* up the css
* */
#RFS-Label {
  margin-top: 12px;
}
#RFS-StepButton {
  z-index: 1000;
}
