.resource-review-item {
  border-radius: 16px;
  border: 1px solid #0e6ba8;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  align-items: center;

  h4 {
    font-weight: bold;
    font-size: 20px;
  }
}