.container {
    display: flex;
    flex-direction: column;

    .qr-container {
        flex: 1;
        padding: 30px;
        padding-top: 10px;
    }

    .copy-to-clipboard {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.qr-modal {
    background-color: white;
}