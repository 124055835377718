.presenter-container {
  margin: 4px 0;
  border-radius: 5px;
  padding: 6px 24px 6px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    font-size: 18px;
  }

  &:nth-child(even) {
    background-color: #eaeafa;
  }

  &:nth-child(odd) {
    background-color: #eaeaea;
  }

  .btn-more {
    padding: 2px 8px;
  }
}

.label {
  font-size: 16px;
}

.fixed-size {
  width: 166px !important;
}

.no-img {
  width: 200px;
  height: 300px;
  border: 1px dashed gray;
  border-radius: 10px;
}

.isHidden {
  display: none;
}

.phone-error,
._2fa-error {
  margin-top: -10px;
  color: rgb(234, 154, 64);

  span {
    cursor: pointer;
    color: #1a1a93;

    &:hover {
      text-decoration: underline;
    }
  }
}

.info-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-start;
  }
}

.image-upload-section {
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    width: 40%;
    padding: 20px;
  }
}

.image-preview {
  width: 100%;
  height: auto;
  margin-bottom: 10px;

  img {
    max-width: 100%;
    height: auto;
  }

  .no-img {
    width: 100%;
    height: 200px;
    background-color: #f0f0f0;
  }
}

.image-upload-button {
  text-align: center;
}

.form-section {
  width: 100%;
  max-width: 500px;

  @media (min-width: 768px) {
    width: 60%;
    padding: 20px;
  }
}

.session-form-section,
.payment-form-section {
  width: 100%;
  margin: auto;

  @media (min-width: 768px) {
    width: 80%;
    padding: 20px;
  }
}

.payment-form-section {
  padding-top: 0;
}

// Adjust form groups for better mobile display
.form-group {
  margin-bottom: 1rem;

  @media (max-width: 767px) {
    .col-form-label {
      margin-bottom: 0.5rem;
    }
  }
}

.phone-error1,
._2fa-error1 {
  color: rgb(234, 154, 64);

  span {
    cursor: pointer;
    color: #1a1a93;

    &:hover {
      text-decoration: underline;
    }
  }
}

.nao-container {
  border: 1px solid #9de0f8;
  border-radius: 10px;
  width: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .nao-days {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    .nao-day {
      display: flex;
      gap: 4px;
      color: #0D1120;
      border-radius: 2px;
      cursor: pointer;
      background: #0e6ba8;

      div {
        font-size: 12px;
        line-height: 18px;
        color: white;
        padding: 3px;
        padding-left: 6px;
      }

      .nao-remove-btn {
        padding: 0 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: #084269;
        }
      }
    }
  }

  .nao-actions {
    display: flex;
    justify-content: flex-end;

    .nao-add-btn {
      cursor: pointer;
      padding: 2px 4px;
      font-size: 14px;
      color: gray;
      border: 1px solid gray;
      border-radius: 4px;

      &:hover {
        color: #0D1120;
        border-color: #0D1120;;
      }

      &:active {
        color: #3d4258;
        border-color: #3d4258;
      }
    }
  }
}