@import "../../../assets/scss/custom/variables.scss";

.m-heading {
  margin-bottom: 12px;
  span {
    font-size: 18px;
    font-weight: bold;
  }
}
.m-title {
  margin-bottom: 12px;
  span {
    font-size: 16px;
  }

  span.link {
    cursor: pointer;
    text-decoration: underline;
    color: $primary;
  }
}

.modal-actions {
  display: flex;
}

.request-booked-session-cancellation-modal {
  .modal-body {
    padding-bottom: 0 !important;
  }

  .modal-footer {
    padding-top: 8px !important;
  }
}