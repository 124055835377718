.loading-teams { 
    height: 400px;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.teams {
    height: 400px;
    overflow-y: scroll;
    padding: 0 10px;
}

.host-group {
    margin-bottom: 20px;

    .group-title {
        margin-bottom: 5px;;
    }
}