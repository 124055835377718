.duplicate-times {
    display: flex;
    align-items: center;

    input {
        margin: 0 10px;
        width: 55px;
    }
}

.duplicate-session {
    .modal-loader {
        display: flex;
        width: 100%;
        height: 120px;
        align-items: center;
        justify-content: center;
    }

    p {
        margin: 0;
    }

    hr {
        margin: 10px 0;
    }
}