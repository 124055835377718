.resource-request-item {
  background: white;
  border-radius: 1rem;
  box-shadow: 10px 12px 24px rgba(206, 222, 238, 0.47);
  padding: 2rem 1rem;
  position: relative;
  padding-right: 40px;

  .resource-title {
    margin-bottom: 8px;

    span {
      font-size: 20px;
      font-weight: bold;
    }
  }

  .request-content {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .request-quantity {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      .form-control {
        width: 120px;
        font-size: 20px;
      }
    }
  }

  .request-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .request-actions {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    i {
      font-size: 24px;
      color: #0e6ba8;
      cursor: pointer;
    }
  }

  @media (min-width: 992px) {
    .request-content {
      flex-direction: row;
    }
  }

  @media (min-width: 560px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-right: 1rem;
    gap: 8px;
  }
}