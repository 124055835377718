.review-ship-order {
  .modal-footer {
    justify-content: space-between;

    .btn-submit-print-desktop {
      display: none;

      @media (min-width: 768px) {
        display: block;
      }
    }

    .btn-submit-print-mobile {
      display: block;

      @media (min-width: 768px) {
        display: none;
      }
    }
  }
}