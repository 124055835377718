@import "../../../../assets/scss/custom/variables.scss";

.resource-filter-group {
  background: #ffffff;
  border: 1px solid $primary;
  box-shadow: 10px 12px 24px $theme-shadow;
  border-radius: 16px !important;
  overflow: hidden;
  margin-bottom: 1em;

  .MuiAccordionSummary-content {
    font-weight: bold;
  }

  .MuiPaper-root {
    background: transparent;
    border: 0;
    box-shadow: none;

    &:before {
      background-color: rgba(#11cdef, 0.49);
    }
  }
}