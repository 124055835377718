@import "../../assets/scss/custom/variables.scss";

.schools-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.school-selector {
	max-width: 400px;
	z-index: 11;
}

.section {
	padding: 0;
	margin-top: 30px;
	margin-bottom: 30px;
}

.availableCredits {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.teachers {
	.teachersHeader {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.loadingContainer {
	width: 100%;
	height: 40vh;
	display: grid;
	place-items: center;
}

.loadingContainer-sm {
	width: 100%;
	height: 85px;
	display: grid;
	place-items: center;
}

.tableRow {
	display: flex;
	flex-direction: row;

	& > * {
		flex-grow: 1;
	}

	.heading {
		font-size: 1em;
		font-weight: 600;
		width: 100%;
		text-align: center;
		text-align: right;
	}

	.heading:first-child {
		text-align: left;
	}

	p {
		width: 100%;
	}

	p {
		text-align: right;
	}

	p:first-child {
		text-align: left;
	}

	.allocateCell {
		width: 80ch !important;
		display: flex;
		justify-content: flex-end;

		input {
			border: 1px solid $secondary;
			padding: 2px;
			margin: 2px;
			border-radius: 10px;
			aspect-ratio: 1.5/1;
			height: 2em;
			background-color: $light-blue;
			text-align: center;
		}
	}
}

li.tableRow {
	margin: 0;
	padding: 0;
}

ul {
	margin: 0;
	padding: 0;
}

.p-action {
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	h3 {
		margin: 20px 0;
		font-weight: 700;
		font-size: 1em;

		span {
			margin-left: 5em;
		}
	}

	div > button:first-child {
		margin-right: 30px;
	}
}

.schoolList {
	display: flex;
	align-items: center;
	gap: 5px;

	h3 {
		font-size: 1em;
		margin: 0;
		font-weight: 700;
	}

	select {
		padding: 8px 10px;
		border: 1px solid $secondary;
		background-color: white;
		border-radius: 5px;
		max-width: 30ch;
		text-overflow: ellipsis;
	}
}

.principal-teacher-list {
	max-height: 510px;
	overflow-y: scroll;
}

.school-charts-grid {
	display: flex;
	gap: 38px;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: stretch;
}

.school-info-container {
	max-height: 100vh;
	overflow-y: scroll;
}

.detail-link {
	cursor: pointer;
	color: #0f6aa8;

	&:hover {
		color: #233dd2;
		text-decoration: underline;
	}
}

.teacher-name {
	cursor: pointer;
	font-weight: bold;
	color: #0f6aa8;
	&:hover {
		color: #233dd2;
		text-decoration: underline;
	}
}

.my-school-tabs {
	margin-top: 1.25rem;

	.nav {
		width: 100%;
		display: flex;
		flex-direction: row;
	}

	.nav-item {
		flex: 1;
		text-align: center;
		display: flex;
		align-items: flex-end;
		.nav-link {
			display: block;
			width: 100%;
			color: #0d1120;
			font-weight: 400;
			border: none;
			border-bottom: 2px solid #9de0f8;
			padding: 0.625rem 0.9375rem;
			font-size: 1.5625rem;

			@media (max-width: 700px) {
				font-size: 1rem;
			}

			&.active {
				color: black;
				border-bottom: 3px solid #0e6ba8;
				font-weight: 700;
				background-color: #f3f9ff;
			}
		}
	}
}

.tab-content {
	margin-top: 1.25rem;
}

.reports-table-container {
	background-color: #fff;
	border: 1px solid #9ce0f8;
	padding: 0.95rem;
	overflow-x: auto;
	border-radius: 1rem;

	h2 {
		color: #0d1120;
		font-weight: 700;
		font-size: 1.875rem;
		margin-bottom: 1.25rem;
		@media (max-width: 700px) {
			margin-bottom: 0.5rem;
		}
	}

	.reports-table {
		width: 100%;
		border-collapse: collapse;

		.table-head {
			border-bottom: 1px solid #9ce0f8;
			th {
				padding: 0 0 0.75rem 0;
				@media (max-width: 700px) {
					padding: 0 1rem;
				}
			}
		}

		td {
			padding: 1.75rem 0;
			@media (max-width: 700px) {
				padding: 1rem;
			}
		}

		.table-data-status {
			border-radius: 62.5px;
			padding: 0.5rem 0.75rem;
		}
		.status-not-started {
			background-color: #ffcccb;
		}
		.status-in-progress,
		.status-draft {
			background-color: #9df8c7;
		}

		.status-needs-review {
			background-color: #fcf2d8;
		}

		.status-comment-received {
			background-color: #c59df8;
		}

		.status-complete,
		.status-submitted {
			background-color: #9de0f8;
		}
	}
}

.school-leaders-section {
	margin-bottom: 2rem;
	padding-bottom: 2rem;
	border-bottom: 2px solid #eee;

	.principal-teacher-list {
		overflow: visible !important;
		max-height: none !important;
	}
}

.teachers-section {
	.principal-teacher-list {
		overflow-y: auto;
		max-height: 40vh;
	}
}

.reporting-layout {
	display: flex;
	flex-direction: column;
	width: 100%;

	.header-section {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1rem;
		width: 100%;
		@media (max-width: 700px) {
			flex-direction: column;
		}
	}

	.filters {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 1rem;
		@media (max-width: 700px) {
			flex-direction: column;
		}
	}
}

.user-graph-card {
	background: #fff;
	border: 1px solid #9ce0f8;
	border-radius: 1.25rem;
	padding: "3.375rem 1.5rem";
}
