@import "../../assets/scss/custom/variables.scss";

.availability {
  margin-top: 32px;
  width: 100%;

  th {
    border: 1px solid rgb(190, 189, 189);
    background-color: lightgray;
  }

  td {
    width: 100px;
    height: 60px;
    border: 1px solid green;
    text-align: center;

    &:hover {
      background: #EFEFEF;
    }
  }

  td.available-cell {
    cursor: pointer;

    &.selected {
      background: $blue-secondary;
      border-color: $blue-secondary;
    }

    &.low-availability {
      background-color: #ffcccb;
    }

    &.medium-availability {
      background-color: #ffb347;
    }

    &.high-availability {
      background-color: #77dd77;
    }

    &.individual-host-available {
      background-color: $blue-secondary;
    }
  }

  thead tr {
    height: 48px;
  }

  tfoot tr td {
    border: 0;
    padding-top: 10px;
  }
}

.head-time {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

.head-weekday {
  width: 64px;
  padding-right: 10px;
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: right;
}

.foot-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0;

  .low-availability-ex,
  .medium-availability-ex,
  .high-availability-ex,
  .selected-ex,
  .unselected-ex,
  .individual-host-available-ex,
  .individual-host-unavailable-ex {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border: 1px solid gray;
  }

  .medium-availability-ex,
  .high-availability-ex {
    margin-left: 16px; // Add left padding here
  }

  .low-availability-ex {
    background-color: #ffcccb;
  }

  .medium-availability-ex {
    background-color: #ffb347;
  }

  .high-availability-ex {
    background-color: #77dd77;
  }

  .selected-ex,
  .individual-host-available-ex {
    background: $blue-secondary;
  }

  .unselected-ex,
  .individual-host-unavailable-ex {
    background: white;
  }

  p {
    font-size: 14px;
    margin: 0;
  }

  .gap {
    width: 40px;
  }
}

.page-head {
  display: flex;
  flex-direction: row;
  align-items: center;

  .page-title {
    flex: 1;
  }

  .page-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.individual-host-available-ex {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background: $blue-secondary;
  border: 1px solid gray;
}

.individual-host-unavailable-ex {
  width: 16px;
  height: 16px;
  margin-left: 16px;
  margin-right: 8px;
  background: white;
  border: 1px solid gray;
}

