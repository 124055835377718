.loadingBar {
  width: 100%;
  height: 5px;
  position: relative;
  display: block;

  &::after {
    content: "";
    display: block;
    width: 5%;
    height: 5px;
    background-color: rgba(#5e72e4, 0.3);
    border-radius: 20px;
    animation: loader 5s linear infinite;
    animation-delay: 0.2s;
  }
}

@keyframes loader {
  0% {
    width: 10%;
  }

  30% {
    width: 70%;
  }

  50% {
    width: 100%;
    background-color: rgba(#5e72e4, 1);
  }

  70% {
    width: 70%;
  }

  85% {
    width: 50%;
  }

  100% {
    width: 5%;
  }
}