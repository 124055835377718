.overlap-modal-processing {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  opacity: 0.3;

  display: flex;
  align-items: center;
  justify-content: center;
}